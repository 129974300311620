import { Component, Injector, OnInit, Signal, inject } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { map } from "rxjs";

import { StartUpShipTransactionEditComponent } from "../start-up-ship-transaction-edit/start-up-ship-transaction-edit.component";
import { TransactionBaseComponent } from "../../economics/transaction-base/transaction-base.component";

import { FleetService } from "../../services/fleet.service";
import { ShipClassService } from "../../services/ship-class.service";

import { Fleet } from "../../interfaces/fleet";
import { ShipClass } from "../../interfaces/ship-class";
import { Transaction } from "../../interfaces/transaction";
import { TableService } from "../../services/table.service";
import { DialogConfig } from "@angular/cdk/dialog";

@Component({
  template: '',
})
export class StartUpShipsComponent extends TransactionBaseComponent implements OnInit {
  protected fleetService = inject(FleetService);
  protected shipClassService = inject(ShipClassService);
  private tableService = inject(TableService);

  override transactionType = 'StartUpShips';

  government: Signal<unknown>;
  readOnly: boolean;
  shipClassHash: Signal<{ [key: string]: ShipClass; } | undefined>;
  fleetHash: Signal<{ [key: string]: Fleet; } | undefined>;

  // extending class MUST override startUpTitle and filter
  startUpTitle: string = 'StartUpShips';

  filter (shipClass: ShipClass) {
    return true;
  };

  constructor () {
    super();

    this.government = toSignal(this.tableService.getGovernmentById(
      this.race.governmentId as number,
      this.race.campaignId as string
    ));

    this.shipClassHash = toSignal<{ [key: string]: ShipClass; }>(
      this.shipClassService.getFilteredShipClassesForRace$(this.race, this.filter).pipe(
        map(shipClasses => {
          let initialShipClassHash: { [key: string]: ShipClass; } = {};
          let hash = shipClasses.reduce(function (scHash, shipClass) {
            scHash[shipClass._id] = shipClass;
            return scHash;
          }, initialShipClassHash);
          return hash;
        })
      )
    );

    this.fleetHash = toSignal(this.fleetService.getFleetsForRaceId$(this.race._id).pipe(
      map(fleets => {
        let initialHash: { [key: string]: Fleet; } = {};
        return fleets.reduce(
          (hash, fleet) => (hash[fleet._id] = fleet, hash),
          initialHash
        );
      })
    ));
  };

  override ngOnInit (): void {
    super.ngOnInit();
    this.readOnly = this.race!.readyForEconomicsTurn! >= this.race!.turnActivated;
  };

  override buildEditDataDialog (documentName: string, document: any): DialogConfig<unknown> {
    let config: DialogConfig<unknown> = super.buildDataDialogConfig(documentName, document);
    config.data = Object.assign(
      config.data,
      {
        shipCategory: this.startUpTitle,
        shipClasses: Object.values(this.shipClassHash()),
        fleets: Object.values(this.fleetHash())
      }
    );
    return config;
  };

  addTransaction () {
    this.editTransaction(undefined);
  }

  editTransaction (transaction: Transaction) {
    this.openTransactionDialog(undefined, transaction, StartUpShipTransactionEditComponent);
  };

  deleteShipTransaction (transaction: Transaction) {
    this.openDeleteTransactionDialog(undefined, transaction);
  };
}
