<div id="_Startup-PCF_" class="flex flex-col iBTTiny:mx-auto md:m-0 md:px-2 iBTTiny:w-11/12 md:w-full">
  <div class="p-2 ism-gold text-1.5">{{ pageTitle }}</div>

  @if (government().pcf === 100) {
    <div>Maximum PCF has already been met on startup allocation.</div>
  } @else {
    <div class="w-full relative">
      <table class="w-full relative text-1.25 border">
        <thead class="w-full top-[3.5rem] z-30 sticky bg-black h-fit">
          <tr>
            <th id="_thead-top-border_" class="p-0 h-[2px]] top-[3px] bg-white" colspan="11"></th>
          </tr>
          <tr>
            <th data-tooltip="Location ID" class="p-2 border bg-black ism-gold tooltip-tr">Locator</th>
            <th data-tooltip="Population Unit" class="p-2 text-center border bg-black ism-gold tooltip-tr">PU</th>
            <th data-tooltip="Free Planetary Ctrl Forces" class="p-2 text-center border bg-black ism-gold tooltip-tr">Free PCF</th>
            <th data-tooltip="Free Planetary Ctrl Forces-a" class="p-2 text-center border bg-black ism-gold tooltip-tr">Free PCF-a</th>
            <th data-tooltip="&Delta; Planetary Ctrol Forces-a" class="p-2 text-center border bg-black ism-gold tooltip-tr">&Delta; PCF</th>
            <th data-tooltip="&Delta; Planetary Ctrol Forces-a" class="p-2 text-center border bg-black ism-gold tooltip-tr">&Delta; PCF-a</th>
            <th data-tooltip="Cost" class="p-2 text-right border bg-black tooltip-tl ism-gold tooltip-tr">Cost</th>
            @if (!readOnly) {
              <th class="p-2 text-center border bg-black ism-gold">Action</th>
            }
          </tr>
          <tr>
            <th id="_thead-bottom-border_" class="p-0 h-[2px]] top-[3px] bg-white" colspan="11"></th>
          </tr>
        </thead>
        <tbody>
          @for (population of starSystemsPopulationInfo[race.starSystemId] | orderBy: "locator"; track population) {
            <tr class="data-odd-light">
              <td data-tooltip="Location ID" class="tooltip-tr">{{ population.locator }}</td>
              <td data-tooltip="Population Unit" class="text-center tooltip-tr">{{ population.pu || 0 | number: "1.0-0" }}</td>
              <td data-tooltip="Free PCF - Planetary Ctrl Forces" class="text-center tooltip-tr">{{ transactionLookupHash[population.planetId]?.freePcf || 0 | number: "1.0-0" }}</td>
              <td data-tooltip="Free PCF-a - Planetary Ctrl Forces-a" class="text-center tooltip-tr">{{ transactionLookupHash[population.planetId]?.freePcfa || 0 | number: "1.0-0" }}</td>
              <td data-tooltip="&Delta; PCF - Planetary Ctrl Forces" class="text-center tooltip-tr">{{ transactionLookupHash[population.planetId]?.PCFQuantity || 0 | number: "1.0-0" }}</td>
              <td data-tooltip="&Delta; PCF-a - Planetary Ctrl Forces-a" class="text-center tooltip-tr">{{ transactionLookupHash[population.planetId]?.PCFaQuantity || 0 | number: "1.0-0" }}</td>
              <td data-tooltip="Cost" class="text-right tooltip-tr">{{ (transactionLookupHash[population.planetId]?.PCFAmount || 0) + (transactionLookupHash[population.planetId]?.PCFaAmount || 0) | number: "1.2-2" }}</td>
              <!-- ACTION BUTTONS -->
              @if (!readOnly && (population.pu < transactionLookupHash[population.planetId]?.maxPu || transactionLookupHash[population.planetId]?.showAnyway)) {
                <td class="text-center w-1/8">
                  <div id="_ICON-SET" class="flex items-center justify-center">
                    @if ((transactionLookupHash[population.planetId]?.PCFQuantity || 0) != 0 || (transactionLookupHash[population.planetId]?.PCFaQuantity || 0) != 0) {
                      <ism-svg data-tooltip="Edit Troop Transaction" class="m-1 tooltip-tl" [name]="'pencilSquare'" (click)="openTroopTransactionDialog(population.planetId)" [classString]="'w-6 h-6  flex items-center text-yellow-300 hover:text-white visited:text-yellow-300'"></ism-svg>
                      <ism-svg data-tooltip="Delete Troop Transaction" class="m-1 tooltip-tl" [name]="'trashcan'" (click)="deleteTroopTransactionDialog(population.planetId)" [classString]="'w-6 h-6 flex items-center text-yellow-300 hover:text-white visited:text-yellow-300'"></ism-svg>
                    }
                    @if ((transactionLookupHash[population.planetId]?.PCFQuantity || 0) === 0 && (transactionLookupHash[population.planetId]?.PCFaQuantity || 0) === 0) {
                      <ism-svg data-tooltip="Add Troops" class="m-1 tooltip-tl" [name]="'plus'" (click)="openTroopTransactionDialog(population.planetId)" [classString]="'w-6 h-6 flex items-center text-yellow-300 hover:text-white visited:text-yellow-300'"></ism-svg>
                      <ism-svg data-tooltip="Remove Troops" class="m-1 tooltip-tl" [name]="'minus'" (click)="openTroopTransactionDialog(population.planetId)" [classString]="'w-6 h-6 flex items-center text-yellow-300 hover:text-white visited:text-yellow-300'"></ism-svg>
                    }
                  </div>
                </td>
              }
            </tr>
          }
          <tr class="data-odd-light">
            <td colspan="5"></td>
            <td class="text-right font-bold ism-gold">Total:</td>
            <td class="text-right font-bold ism-gold">{{ total() || 0 | number: "1.2-2" }} MC</td>
            @if (!readOnly) {
              <td></td>
            }
          </tr>
        </tbody>
      </table>
    </div>
  }
</div>
