import { AfterViewInit, Component, ContentChild, Injector, Signal, WritableSignal, computed, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from "@angular/router";
import { tap } from 'rxjs';

import { authorizedMarshal } from '../../auth/auth.guard';

import { InformationPopulationService } from '../../services/information-population.service';
import { ProjectService } from '../../services/project.service';
import { RaceService } from '../../services/race.service';
import { SessionService } from '../../services/session.service';
import { ShipClassService } from '../../services/ship-class.service';
import { TableService } from '../../services/table.service';
import { TransactionService } from '../../services/transaction.service';

import { TurnSelectorComponent } from '../../shared/turn-selector/turn-selector.component';

import { Campaign } from '../../interfaces/campaign';
import { Government } from '../../interfaces/government';
import { InformationPopulation } from '../../interfaces/information-population';
import { Race } from '../../interfaces/race';
import { ShipClass } from '../../interfaces/ship-class';
import { KeyedMiniTransaction, Transaction } from '../../interfaces/transaction';

@Component({
  selector: 'su-summary',
  templateUrl: './start-up-summary.component.html',
  host: {
    'class': 'flex flex-grow'
  }
})
export class StartUpSummaryComponent implements AfterViewInit {
  @ContentChild('turnSelector') turnSelector: TurnSelectorComponent | undefined;

  pageTitle: string = "Budget Summary";
  injector = inject(Injector);
  router = inject(Router);
  campaign?: Campaign = undefined;
  race?: Race = undefined;
  government: Signal<Government | undefined>;
  isPlayerRace: boolean;
  infoPopPrimary: Signal<InformationPopulation | undefined>;
  project: any;
  warshipAllocation: number = 0;
  defenseAllocation: number = 0;
  freighterAllocation: number = 0;
  transactions: Signal<Transaction[] | undefined>;
  startUpTurn: WritableSignal<number> = signal(1);
  altMinTurn: number = 1;
  validSy: boolean = true;
  validX: boolean = true;
  validWarships: boolean = false;
  validDefenses: boolean = false;
  validFreighters: boolean = false;
  validFinalBalance: boolean = false;
  validFinalSubmit: boolean = false;
  userCanChangeMind: boolean = false;
  canFinalize: boolean = false;
  canReset: boolean = false;
  shipClassHash: Signal<{ [key: string]: ShipClass; } | undefined> | undefined;
  authorizedMarshal: boolean;
  summarizedTransactions: Signal<KeyedMiniTransaction | undefined>;
  freeSyTransaction: Transaction | undefined;

  constructor (
    public session: SessionService,
    private informationPopulationService: InformationPopulationService,
    private projectService: ProjectService,
    private raceService: RaceService,
    private shipClassService: ShipClassService,
    private tableService: TableService,
    private transactionService: TransactionService,
  ) {
    session.iAmBusy();
    this.authorizedMarshal = authorizedMarshal();
    this.campaign = this.session.getCampaign();
    this.race = this.session.getRace();
    this.startUpTurn.set(this.race.turnActivated || this.race.turnCreated || 1);

    if (this.campaign == null || this.race == null) {
      this.router.navigate(['/user/assignments']);
    }

    // this.session.turnEdit.set(this.startUpTurn());
    this.isPlayerRace = (this.race!.playerRace === 1) || false;

    this.government = toSignal(this.tableService.getGovernmentById(
      this.race!.governmentId as number,
      this.race!.campaignId as string
    ));

    this.infoPopPrimary = toSignal(this.informationPopulationService.getInformationPopulationForRaceIdAndPlanetId(this.race!._id as string, this.race!.homePlanetId as string));

    let turnActivated = this.race!.turnActivated || this.race!.turnCreated || 1;

    let code = '';
    let techLevel = this.race!.techLevel || 0;
    if (techLevel === -1) {
      code = '-0199';   // economic level research to level 0
    }
    else if (techLevel === 0) {
      code = '0099';   // economic level research to level 1
    }
    else {
      code = '0113';  // SY - Shipyard
    }

    this.project = toSignal(this.projectService.getProjectByRaceAndCode(this.race!._id || '', code));

    // meat and po-ta-toes
    this.transactions = toSignal(this.transactionService.getOrBuildTransactions(this.race as Race, this.startUpTurn()).pipe(
      tap(transactions => this.freeSyTransaction = transactions.find(t => t.locator === 'FreeSY') || undefined)
    ));
    this.summarizedTransactions = computed(() => {
      return this.transactionService.buildSummaryFromTransactions((this.transactions() || []), this.government());
    });
    effect(() => {
      let summaryData = this.summarizedTransactions();
      if (Object.keys(summaryData || {}).length === 0) {
        return;
      }
      this.processTransactionData(this.summarizedTransactions());
    });

    this.session.iAmNotBusy();

    console.log('startup summary', this);
  };

  ngAfterViewInit () { };

  setStartupTurnFlags () {
    let readyTurn = this.race!.readyForEconomicsTurn || 0;
    let completeTurn = this.race!.economicsCompleteTurn || 0;
    this.validFinalSubmit = this.validWarships && this.validDefenses &&
      this.validFreighters && this.validFinalBalance && (readyTurn < this.startUpTurn());
    this.userCanChangeMind = (readyTurn === this.startUpTurn()) && (completeTurn < this.startUpTurn());
    this.canFinalize = this.authorizedMarshal && (readyTurn === this.startUpTurn()) && (completeTurn < this.startUpTurn());
    this.canReset = this.authorizedMarshal && (completeTurn === this.startUpTurn()) && (this.session.turnEdit() === this.session.turnCampaign);
  };

  processTransactionData (summary: KeyedMiniTransaction = {}) {
    if (Object.keys(summary).length === 0) {
      return summary;
    }

    this.session.iAmBusy();

    let gov = this.government() as Government;
    let income: number = summary['popIncomeTotal'].amount;
    let warshipsTotal: number = summary['WarshipsTotal'].amount;
    let defensesTotal: number = summary['DefensesTotal'].amount;
    let freightersTotal: number = summary['FreightersTotal'].amount;
    let populationTotal: number = summary['PopulationTotal'].amount;
    let troopsTotal: number = summary['troopsTotal'].amount;
    let iuCost = summary['industrialTotal'].amount;
    let grandTotal = warshipsTotal + defensesTotal + freightersTotal + populationTotal + troopsTotal + iuCost;

    if (this.race!.techLevel > 0) {
      // Segments are valid if they are within 10% of the budget
      this.warshipAllocation = Math.max((income * gov.warships / 100), (income * 0.10));
      this.validWarships = this.isPlayerRace || Math.abs(1 - (warshipsTotal / this.warshipAllocation)) < 0.10;

      this.defenseAllocation = Math.max((income * gov.defense / 100), (income * 0.10));
      this.validDefenses = this.isPlayerRace || Math.abs(1 - (defensesTotal / this.defenseAllocation)) < 0.10;

      this.freighterAllocation = Math.max((income * gov.freighters / 100), (income * 0.10));
      this.validFreighters = this.isPlayerRace || Math.abs(1 - ((freightersTotal + populationTotal) / this.freighterAllocation)) < 0.10;
    }
    else {
      this.validWarships = true;
      this.validFreighters = true;
      this.validDefenses = (1 - (defensesTotal / (income * 0.05))) < 0.05;
    }
    let finalBalancePercent = (grandTotal / income);
    this.validFinalBalance = this.isPlayerRace ?
      (finalBalancePercent > 0.975) && (finalBalancePercent < 1) :
      grandTotal < income;
    this.setStartupTurnFlags();

    this.session.iAmNotBusy();

    return summary;
  };

  updateRace (raceChange: { [key: string]: any; }): void {
    this.session.iAmBusy();
    this.raceService.saveRace(raceChange).subscribe(
      ((race) => {
        this.race = race;
        this.session.iAmNotBusy();
      })
    );
  };

  updateEconomicTurn (newTurn: number) {
    // this.session.iAmBusy();
    // this.startUpTurn.set(newTurn);
    this.session.turnEdit.set(newTurn);
    // this.session.iAmNotBusy();
  };

  validateStartUp () {
    this.session.iAmBusy();

    let shipClasses = toSignal<ShipClass[]>(
      this.shipClassService.getShipClassesForRaceWithCount$(this.race),
      { injector: this.injector }
    );

    effect(() => {
      if (!shipClasses() || !this.transactions()) {
        return;
      }

      this.session.iAmBusy();
      let shipClassHash: { [key: string]: ShipClass; } = shipClasses().reduce((hash, shipClass) => {
        hash[shipClass._id] = shipClass;
        return hash;
      }, {});

      let shipTransactions: Transaction[] = this.transactions().filter(
        transaction => {
          let key: string = transaction.type;
          return (key === 'Warships' || key === 'Defenses' || key === 'Freighters' || transaction.locator === "FreeSY");
        }
      );

      this.validSy = true;
      this.validX = true;

      if (!this.isPlayerRace) {
        let xTotal = 0;
        let syTotal = 0;
        let freeSY = 0;
        for (const transaction of shipTransactions) {
          if (transaction.locator === "FreeSY") {
            freeSY = transaction.quantity || 0;
          }
          else if (transaction.shipClassId) {
            let shipClass = shipClassHash[transaction.shipClassId];
            xTotal += (transaction.quantity! * shipClass.xCount!);
            syTotal += (transaction.quantity! * shipClass.syCount!);
          }
        }
        this.validX = ((this.race!.maxX || 999) >= xTotal);
        this.validSy = (freeSY <= syTotal);
      }
      let raceChange;
      if (this.validX && this.validSy) {
        this.race!.readyForEconomicsTurn = this.startUpTurn();
        raceChange = {
          _id: this.race!._id,
          readyForEconomicsTurn: this.startUpTurn()
        };
      }

      this.setStartupTurnFlags();

      if (raceChange) {
        this.updateRace(raceChange);
      }
      this.session.iAmNotBusy();
    }, { injector: this.injector });

    this.session.iAmNotBusy();
  };

  changedMyMind () {
    this.race!.readyForEconomicsTurn = (this.race!.readyForEconomicsTurn || 1) - 1;
    this.setStartupTurnFlags();
    let raceChange = {
      _id: this.race!._id,
      readyForEconomicsTurn: this.race!.readyForEconomicsTurn
    };
    this.updateRace(raceChange);
  };

  finalizeStartUp () {
    this.session.iAmBusy();
    this.transactionService.performStartup(this.race!._id, this.startUpTurn()).subscribe(
      (raceUpdated) => {
        setTimeout(() => {
          this.race = raceUpdated as Race;
          this.session.setRace(this.race);
          this.setStartupTurnFlags();
          this.session.iAmNotBusy();
        });
      }
    );
  };

  rollbackStartUp () {
    this.session.iAmBusy();
    this.transactionService.rollbackStartup(this.race!._id, this.startUpTurn()).subscribe(
      (updatedRace) => {
        setTimeout(() => {
          this.race = updatedRace as Race;
          this.session.setRace(this.race);
          this.setStartupTurnFlags();
          this.session.iAmNotBusy();
        });
      }
    );
  };
};
