import { Component, Injector, OnInit, Signal, WritableSignal, computed, effect, inject, signal } from '@angular/core';
import { map, tap } from 'rxjs';

import { InformationPlanetService } from '../../services/information-planet.service';
import { InformationPopulationService } from '../../services/information-population.service';
import { SessionService } from '../../services/session.service';

import { InformationPlanet } from '../../interfaces/information-planets';
import { Race } from '../../interfaces/race';
import { InformationPopulation } from '../../interfaces/information-population';
import { TransactionService } from '../../services/transaction.service';
import { Transaction } from '../../interfaces/transaction';
import { toSignal } from '@angular/core/rxjs-interop';
import { DialogData } from '../../interfaces/dialog-data';
import { Dialog } from '@angular/cdk/dialog';
import { PopulationTransactionEditComponent } from '../population-transaction-edit/population-transaction-edit.component';
import { AreYouSure2Component } from '../../shared/are-you-sure2/are-you-sure2.component';
import { TransactionBaseComponent } from '../../economics/transaction-base/transaction-base.component';

@Component({
  selector: 'population',
  templateUrl: './population.component.html',
})
export class PopulationComponent extends TransactionBaseComponent implements OnInit {
  private injector = inject(Injector);
  pageTitle: string = "Population";
  override transactionType = 'Population';
  readOnly: boolean = true;

  colonizableLookupHash: { [key: string]: any; } = {};

  planetInfo: Signal<InformationPlanet[]>;
  populationInfo: Signal<InformationPopulation[]>;
  bodyData: WritableSignal<{ [key: string]: any; }[]> = signal<{ [key: string]: any; }[]>([]);

  processColonizableChange = function (bodyId: string, quantity: number = 0, amount: number = 0) {
    let colonizableData = this.colonizableLookupHash[bodyId];
    if (quantity > 0) {
      colonizableData['quantity'] = quantity;
      colonizableData['amount'] = amount;
    }
    else {
      delete colonizableData.transactionId;
      colonizableData['quantity'] = 0;
      colonizableData['amount'] = 0;
    }
    this.colonizableLookupHash[bodyId] = colonizableData;
    this.bodyData.set(Object.values(this.colonizableLookupHash));
  };

  constructor (
    private informationPlanetService: InformationPlanetService,
    private informationPopulationService: InformationPopulationService,
  ) {
    super();

    this.updatedTransaction.subscribe(transaction => {
      this.processColonizableChange(transaction.bodyId, transaction.quantity, transaction.amount);
    });

    this.deletedTransaction.subscribe(transaction => {
      this.processColonizableChange(transaction.bodyId, -transaction.quantity, -transaction.amount);
    });
  };

  /**
   * Initialize the component and retrieve necessary data from the session and services.
   *
   * This page is different from the other start up pages because
   * population transfer units (PTU) can be added to all colonizable
   * planets and moons in the home star system except where the PTU
   * has been maximized already.
   *
   * All colonizable bodies have been surveyed for a high tech level race.
   * Step 1:
   * This data, informationPlanet[], is the starting properties of colonizableLookupHash.
   * As it is 'keyed' on bodyId, any updates are easily referenced.
   * Step 2:
   * Existing population and economic properties are added to the hash.
   * Step 3:
   * Population added via transactions (quantity, amount) are added to the hash.
   * Step 4:
   * The objects of the hash are extracted to a signal for simple updates
   */
  override ngOnInit (): void {
    super.ngOnInit();
    this.readOnly = this.race!.readyForEconomicsTurn! >= this.race!.turnActivated;

    this.planetInfo = toSignal(
      this.informationPlanetService.getInformationPlanetsForRaceAndStarSystemIds(this.race._id, this.race.starSystemId),
      { injector: this.injector }
    );
    this.populationInfo = toSignal(
      this.informationPopulationService.getInformationPopulationForRaceIdAndStarSystemId(this.race._id, this.race.starSystemId),
      { injector: this.injector }
    );

    this.deletedTransaction.subscribe((transactionToDeleted) => {
      let bodyId = transactionToDeleted.bodyId;
      this.processColonizableChange(bodyId, 0, 0);
    });

    effect(() => {
      if (!this.planetInfo() || !this.populationInfo() || !this.transactions()) {
        return;
      }

      // Step 1
      this.colonizableLookupHash = this.planetInfo().reduce((newHash, informationPlanet: InformationPlanet) => {
        let hd = informationPlanet.habitDifference;
        let multiplier = (hd <= 10) ? 1 :
          (hd <= 12) ? 0.7 : 0.6;
        let maxPu = (hd <= 2) ? 3200 :
          (hd < 10) ? 800 :
            (hd <= 10) ? 150 :
              (hd <= 12) ? 50 :
                (hd <= 18) ? 16 : 0;
        if (informationPlanet.locator.indexOf('(A)') > -1) {
          if (informationPlanet.radius) {
            let hexes = Math.ceil(informationPlanet.radius / 12) * 6;
            // population centers allowed ===  5 per hex === 5 * hexes
            let popCenters = 5 * hexes;
            // max ptu per <population center> === 16 ptu (outpost)
            maxPu = popCenters * 16;
          }
        }
        let mult = (hd <= 2) ? 3 :
          (hd < 10) ? 4 :
            (hd === 10) ? 5 :
              (hd <= 12) ? 6 : 7;
        let ip = Object.assign(informationPlanet, {
          multiplier: multiplier,
          maxPu: maxPu,
          cost: mult * 5
        });
        newHash[informationPlanet.bodyId] = ip;
        return newHash;
      }, this.colonizableLookupHash);   // initialized to {}

      // Step 2
      this.populationInfo().forEach((ip: InformationPopulation) => {
        let baseObject = this.colonizableLookupHash[ip.planetId] || {};
        this.colonizableLookupHash[ip.planetId] = Object.assign(baseObject, {
          ptu: ip.ptu,
          pu: ip.pu,
          maxPu: ip.maxPu || baseObject.maxPu,  // override is possible
          iu: ip.iu,
          multiplier: ip.multiplier,
          rei: ip.rei,
        });
      });

      // Step 3
      this.transactions().forEach((transaction: Transaction) => {
        let baseObject = this.colonizableLookupHash[transaction.bodyId] || {};
        this.colonizableLookupHash[transaction.bodyId] = Object.assign(baseObject, {
          transactionId: transaction._id,
          quantity: transaction.quantity,
          amount: transaction.amount
        });
      });

      // Step 4
      this.bodyData = signal(Object.values(this.colonizableLookupHash));
      this.session.iAmNotBusy();
    }, { injector: this.injector });
  };

  processChangedTransaction (transaction: Transaction) {
    if (transaction.quantity <= 0) {
      this.deleteTransaction(transaction);
    }
    else {
      this.saveTransaction(transaction);
    }
  };

  override processEditResult (result: { [key: string]: any; }) {
    let transaction: Transaction = result['document'] as Transaction;
    this.processChangedTransaction(transaction);
  };

  editTransaction (bodyId: string, transactionId: string = undefined) {
    let transaction: Transaction = this.transactionById[transactionId];
    if (!transaction) {
      // a new transaction is created
      transaction = this.newTransaction();
      transaction.bodyId = bodyId;
    }
    let config = this.buildDataDialogConfig("Transaction", transaction);
    config.data['bodyData'] = this.colonizableLookupHash[bodyId];
    this.openTransactionDialog("Transaction", transaction, PopulationTransactionEditComponent, config);
  };

  openDeleteTransaction (transactionId: string) {
    this.openDeleteTransactionDialog("Population Transaction", this.transactionById[transactionId]);
  };
};
